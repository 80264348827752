.pk__dropdown {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
}

.pk__content {
    position: absolute;
    z-index: 3000;
    max-width: 95%;
    width: 300px;
    top: 47px;
    right: 0;
}

.pk__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2999;
}