.pk-modal-container {
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 4000;
}

.pk-modal-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #96969659;
}

.pk-modal-content {
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.2s ease-in-out;
}

.pk-modal-content {
    width: 95%;
    max-height: 95%;
}

@media (min-width: 640px) {
    .pk-modal-content.sm {
        width: 400px;
        max-width: 90%;
    }

    .pk-modal-content.md {
        width: 550px;
        max-width: 90%;
    }

    .pk-modal-content.lg {
        width: 800px;
        max-width: 90%;
    }
}
