
.pk-tab-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pk-tab-content {
  position: relative;
  overflow-y: overlay;
  display: flex;
  flex-grow: 1;
}