:root {
  font-family: 'Poppins', sans-serif;
}

.app {
  /* background-color: #282c34; */
  min-height: 100vh;
  /* color: white; */
  display: flex;
  flex-direction: column;
}