@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --sm: 640px;
    --md: 768px;
    --lg: 1024px;
    --xl: 1280px;
    --xxl: 1536px;
}