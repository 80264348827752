.pk-aside-container {
    display: flex;
    top: 10px;
    bottom: 0;
    right: 0;
    position: fixed;
    width: 95%;
    z-index: 2000;
    padding: 10px;
    animation: fadeIn 0.2s ease-in-out;
}

.pk-aside-window {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.closing {
    animation: fadeOut 0.2s ease-in-out !important;
}

@media (min-width: 640px) {
    .pk-aside-container {
        width: 520px;
        max-width: 90%;
    }
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(207, 207, 207, 0.3);
	background-color: rgb(202, 202, 202);
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #7a7a7a;
}

::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(207, 207, 207, 0.3);
	background-color: #2c2c2c;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(2rem);
    }
}