body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* :root {
  --bg-main: rgb(255, 255, 255);
  --bg-main-100: hsl(220, 14%, 96%);
  --bg-main-200: hsl(225, 3%, 58%);

  --bg-accent: cyan;

  --text-main: hsl(215, 28%, 17%);
  --text-hint: hsl(215, 18%, 60%);
}

.text-main {
  color: var(--text-main);
}

.text-hint {
  color: var(--text-hint);
} */